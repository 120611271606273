import React from "react";

import PageWrapper from "../components/PageWrapper";


const CertificationsPage = () => {
    return (
        <>
            <PageWrapper darkTheme>

            </PageWrapper>
        </>
    );
};

export default CertificationsPage;
